@import "../../styles/variables.scss";

/* Button Styling */

.Button {
  @include flex;
  @include center;
  height: 36px;
  border-radius: $borderRadius;
  padding: 0 12px;
  padding-bottom: 1px; // centering text w/ line-height
  font-size: $fontBase;
  font-weight: $fontSemibold;
  background-color: $primary500;
  color: $white;
  flex-shrink: 0;
  width: fit-content;
  gap: 8px;
  text-decoration: none;
  cursor: pointer;
  border: 1px solid $primary400;
  transition: background-color 150ms ease;
  position: relative;
  text-wrap: nowrap;

  &:hover {
    background-color: $primary700;
  }

  &.compact {
    font-size: $fontSmallest;
    height: 20px;
    padding: 0 4px;
    gap: 4px;
    border-radius: $borderRadius/2;
  }

  &.loading {
    opacity: 0.7;
    position: relative;
    &::after {
      content: "";
      @include center;
      width: 20px;
      height: 20px;
      border: 4px solid $gray700;
      border-left-color: $gray400;
      border-radius: 50%;
      animation: spin 1s linear infinite;
      position: absolute;
    }
  }

  @media (max-width: $tablet) {
    font-size: $fontSmall;
  }

  // e.g. arrow icon
  span {
    font-size: 22px;
    font-weight: 300;
  }

  &.disabled {
    opacity: 60%;
    cursor: not-allowed;
  }

  &:disabled {
    opacity: 60%;
    cursor: not-allowed;
  }

  &.noOutline {
    border: none;
    background-color: transparent;
    color: currentColor;
    box-shadow: none;
  }

  // secondary button style
  &.secondary,
  &.secondaryFull,
  &.secondaryFullTextWrap {
    background: $white;
    border-color: $gray300;
    color: $gray500;

    &:hover {
      background-color: $gray50;
    }
  }

  &.secondaryFull,
  &.secondaryFullTextWrap {
    width: 100%;
  }

  // secondary button style
  &.tertiary {
    background: $gray100;
    border-color: $gray100;
    color: $gray500;

    &:hover {
      background-color: $grey200;
    }
  }

  &.danger {
    background-color: rgba(217, 45, 32, 1);
    border-color: $errorBorder;
  }

  &.tertiaryColor {
    background-color: $primary50;
    border-color: $primary50;
    color: $primary700;
    padding: 10px 8px;
  }

  // CTA button style
  &.callout {
    border-radius: $spaceXL;
    border: none;
    background: $tertiary;
    color: $primary;
    padding: 20px 36px;
    font-size: $fontLg;
    width: 300px;
    background: linear-gradient(
      90deg,
      rgb(246, 142, 95) 0%,
      rgba(78, 2, 80) 100%
    );
    animation: grow 3s ease 0s infinite forwards;
  }

  &.fullWidth {
    width: 100%;
    gap: $padding;
  }

  &.icon {
    @include flex;
    padding: $padding calc($padding/2);
    background: transparent;
    max-width: min-content;
    min-width: unset;
    border: none;
    background-color: transparent;
    box-shadow: none;

    &.disabled {
      background-color: $gray100;
    }

    &.selected {
      background-color: $gray800;
    }
  }

  &.secondaryFullTextWrap {
    min-height: 36px;
    height: auto;

    & > p {
      color: rgb(102, 112, 133) !important;
      max-width: 100%;
      text-wrap: wrap;
      font-weight: 500;
    }
  }

  .NotificationLink {
    background: $errorBorder;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
  }

  // TO DO: remove after settings re-style
  &.action {
    @include flex;
    min-width: unset;
    max-width: min-content;
    color: $secondary;
    border-radius: 5px;
    background: transparent;
    padding: 0;

    &.selected {
      background-color: rgba(52, 66, 83, 1);
    }
  }

  &.delete {
    background-color: $error50;
    border-color: $error300;
    color: $errorText;
    gap: $padding;

    &:hover {
      background-color: $error200;
    }
  }

  &.success {
    width: 100%;
    background-color: $success25;
    border-color: $successBorder;
    color: $successText;

    &:hover {
      background-color: $success;
    }
  }
}

.spinner {
  border: 4px solid $gray700;
  border-left-color: $gray400;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
