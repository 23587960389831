@import "@/styles/variables.scss";

// entire detail calendar view
.Calendar {
  .CalendarControls {
    display: flex;
    align-items: center;
    color: $parioBlue;
    gap: $padding;
    margin-bottom: 10px;

    &.adjustUp {
      margin-top: -45px; // shift controls down into tab area
    }

    // navigation changing month being viewed
    .monthNav {
      display: flex;
      @include center;

      // centers month & gives consistent width to avoid jumping on month change
      div {
        display: flex;
        width: 160px;
        @include center;
        // do not wrap text
        white-space: nowrap;
      }

      // custom padding for the nav buttons
      button {
        padding: 9px;
      }

      .end {
        margin-left: auto;
        display: none;
      }
    }
  }

  .scheduleWrapper {
    overflow-x: scroll;
    scrollbar-width: thin;
  }

  .StaffScheduleGrid {
    display: grid;
    grid-template-columns: 200px repeat(7, minmax(140px, 1fr));
    /* 1 column for staff + 7 columns for days */
    position: relative;
    padding: 0;

    .gridCell {
      @include flexCol;
      padding: $padding 0;
      border-left: 1px solid $gray500;
      gap: calc($padding / 4);
      transition: all 200ms ease-out;
      min-height: 121px;

      .date {
        margin-left: auto;
        margin-bottom: $padding;
        margin-right: $padding;
        @include flex;
        @include center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: transparent;
        transition: all 200ms ease;
      }

      // no border at edges of calendar
      &:nth-child(7n + 1) {
        border-left: none;
      }

      &:nth-child(n + 15) {
        border-top: $gridBorder;
      }

      &.selected {
        background-color: $gray50;
      }

      // header has special styles in schedule calendar
      &.header {
        border: none;
        min-height: unset;
        border-bottom: 3px solid $grey200;
        @include center;
        justify-content: flex-start;
        background-color: $gray50;
        color: $gray500;
        padding-bottom: calc($padding/2);

        &.isWeekView {
          padding: 0;
        }

        .dateWrapper {
          @include flex;
          @include center;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          gap: calc($padding/2);
          background-color: transparent;
          transition: all 200ms ease;

          .dateWrapper {
            @include flex;
            @include center;
            gap: 4px;
            color: $gray500;
            transition: all 200ms ease-in;

            span {
              height: 100%;
              @include flex;
              @include center;
            }

            &.disabled {
              color: $gray400;
            }

            &.selected {
              color: $parioBlue;

              p {
                font-weight: $fontBold;
              }
            }
          }
        }

        p {
          margin: unset;
          margin-bottom: 0;
        }
      }
    }
  }

  // the calendar display itself
  .CalendarGrid {
    // general styles shared across overview & scheduling calendars
    overflow-y: auto;
    max-height: calc(100vh - 300px);
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    /* Adding minmax(0, 1fr) */
    padding: calc($padding/2) $padding;
    position: relative;

    .gridCell {
      @include flex;
      padding: calc($padding/2);
      background-color: $white;
      color: $gray700;

      &.disabled {
        color: $gray400;
      }

      button {
        overflow: hidden;
        width: calc(100% - 10px);

        p.apptSummary {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: $fontSmall;
          min-width: 100px;
          width: 100%;
          text-align: left;
          color: $gray900;
          padding: 0 5px;
          border-left: 2px solid transparent;

          // color variations
          &.infoGrey {
            background-color: $gray50;
            border-color: $gray400;
          }

          &.error {
            background-color: $error25;
            border-color: $errorBorder;
          }

          &.success {
            background-color: $success25;
            border-color: $successBorder;
          }

          &.warning {
            background-color: $warning25;
            border-color: $warningBorder;
          }
        }
      }

      // selected date circle
      .date {
        @include flex;
        @include center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: transparent;
        transition: all 200ms ease;

        &.selected {
          background-color: $blue100;
        }

        &.highlighted {
          background-color: $parioBlue;
          color: $white;
        }
      }

      .dateWrapper {
        @include flex;
        @include center;
        gap: 4px;
        color: $gray500;
        transition: all 200ms ease-in;

        span {
          height: 100%;
          @include flex;
          @include center;
        }

        &.disabled {
          color: $gray400;
        }

        &.selected {
          color: $parioBlue;

          p {
            font-weight: $fontBold;
          }
        }
      }

      &.header {
        color: $gray400;
        overflow: hidden;

        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &.ScheduleView {
      // styles specifically for scheduling / detail calendars
      padding: 0;

      .gridCell {
        @include flexCol;
        padding: $padding 0;
        border-left: 1px solid $grey200;

        gap: calc($padding / 4);
        transition: all 200ms ease-out;
        min-height: 121px;

        .date {
          margin-left: auto;
          margin-bottom: $padding;
          margin-right: $padding;
        }

        // no border at edges of calendar
        &:nth-child(7n + 1) {
          border-left: none;
        }

        &:nth-child(n + 15) {
          border-top: $gridBorder;
        }

        &.selected {
          background-color: $gray50;
        }

        // header has special styles in schedule calendar
        &.header {
          border: none;
          min-height: unset;
          border-bottom: 3px solid $grey200;
          align-items: flex-start;
          justify-content: flex-start;
          background-color: $gray50;
          color: $gray500;
          padding: calc($padding/2);
          border-left: 1px solid $gray300;

          &:first-child,
          &:nth-child(2) {
            border-left: none;
          }

          .dateWrapper {
            margin: unset;
          }

          p {
            margin: unset;
            margin-bottom: 0;
          }
        }
      }
    }

    &.WeekView {
      // special styles for calendar view zoomed into days or set of days / week
      row-gap: $padding;

      // wrapper for a single day & it's appointments
      .Day {
        @include flexCol;
        background-color: $white;
        width: 100%;
        border-left: 1px solid $gray300;
        margin-top: 10px;

        &:nth-child(2) {
          border: none;
        }

        // special styles for column that displays time
        &.timeColumn {
          border: none;
        }

        // time slot styles (default 30 minutes)
        .slot {
          border-bottom: $gridBorder;
          border-color: $gray300;
        }

        &:not(.accessible, .timeColumn) {
          .slot:nth-child(2n) {
            border-bottom: $gridBorder;
            border-color: $gray300;
          }
        }

        // time slot styles (default 30 minutes)

        .slot {
          flex: 1;
          display: flex;
          @include center;
          padding: $padding;
          font-size: $fontSmall;
          color: $gray300;
          position: relative;
          background-color: transparent;
          transition: background-color 200ms ease-in-out;
          min-height: 30px;
          cursor: pointer;

          &.unavailable {
            background-color: $grey200;
          }

          &:not(.slotTimeIndicator):hover {
            background-color: $blue50;
          }

          // annotation on left side showing time
          &.slotTimeIndicator {
            border: none;
            align-items: flex-start;
            padding: 0;
            position: relative;

            // position timestamp
            :first-child {
              position: absolute;
              transform: translateY(-50%);
            }
          }

          // appointment indicator card
          .appointmentCell {
            position: absolute; // positioned according to time slot
            top: 0;
            left: 2px;
            // allow for click events to schedule multiple appts in same slot
            width: calc(100% - 22px);
            margin-left: -1px;
            padding: calc($padding / 4);
            padding-bottom: 0;
            background-color: $success25;
            border-left: 3px solid transparent;
            z-index: 1;
            @include flexCol;
            text-align: left;
            overflow: hidden;
            border-radius: calc($borderRadius/2);

            transition: all 150ms ease;

            &:hover {
              z-index: 2;
              height: 120px !important;
            }

            .wrapper {
              overflow: hidden;
              text-wrap: nowrap;

              .apptTypeBadge {
                padding: 0 4px;
                border-radius: 3px;
                font-size: 9px;
                width: fit-content;
                background-color: #ffffff90;
                color: $gray900;
              }

              .participantBadges,
              .nameWrapper {
                @include flex;
                align-items: center;
                gap: 2px;
              }

              .participantIndicator {
                width: $padding;
                height: calc($padding/2);
                background-color: $gray900;
                border-radius: 2px;
              }

              .quickView {
                @include flex;
                align-items: center;
                justify-content: space-between;
                gap: calc($padding/2);
                flex-wrap: wrap-reverse;
                overflow: hidden;
              }
            }

            &.overlapping {
              box-shadow: 0px -3px 6px white;
            }

            // color variations
            &.infoGrey {
              background-color: $gray100;
              border-color: $gray100;
            }

            &.info {
              background-color: $blue100;
              border-color: $blue100;
            }

            &.error {
              background-color: $error25;
              border-color: $errorBorder;
            }

            &.success {
              background-color: $success25;
              border-color: $successBorder;
            }

            &.warning {
              background-color: $warning25;
              border-color: $warningBorder;
            }

            &.scheduleEvent {
              width: 12px;
              border: none;
              overflow: visible;

              .flag {
                display: flex;
                align-items: center;
                padding: calc($padding/4);
                border-radius: $borderRadius;
                position: absolute;
                top: 0;

                .scheduleInfo {
                  background-color: $white;
                  padding: calc($padding/4) calc($padding/2);
                  border-radius: $borderRadius;

                  p {
                    font-size: $fontSmall;
                    color: $gray500;
                    width: max-content;
                  }
                }
              }
            }
          }
        }
      }

      // line that indicates current time
      .timeMarker {
        width: calc(100% - 70px);
        margin-left: 70px;
        height: 3px;
        background-color: $parioBlue;
        color: $parioBlue;
        position: absolute;
        z-index: 2;
        transition: all 200ms ease;

        // timestamp text e.g. 9:00
        .timestamp {
          position: absolute;
          transform: translate(calc(-100% + 5px), calc(-50%));
          left: -10px;
          color: inherit;
          background-color: rgba(
            255,
            255,
            255,
            0.5
          ); // make visible above permanent timestamps
        }

        // circle at end of line
        // .endMarker {
        //   position: absolute;
        //   transform: translate(-100%, -3.5px);
        //   width: 10px;
        //   height: 10px;
        //   border-radius: 50%;
        //   background-color: inherit;
        // }
      }
    }
  }
}

.ScheduleFilter {
  .filterWrapper {
    @include flexCol;
    gap: $padding;
    padding: $padding;
    padding-bottom: $padding * 1.5;
  }

  .filterContent {
    @include flexCol;
    gap: $padding;

    button {
      font-size: $fontSmall;
      font-weight: 400;
    }

    .calendarIndicator {
      width: $padding;
      height: calc($padding/2);
      border-radius: 2px;
      // default color
      background-color: $gray500;
    }
  }

  .filterFlex {
    display: flex;
    align-items: center;
    gap: calc($padding / 2);

    &.all {
      background-color: $gray100;
      padding: calc($padding/4) 0;
      border-radius: 2px;
    }

    p {
      flex-shrink: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.AppointmentDropdownSlot {
  color: $gray400;
  @include flexCol;
  padding: 5px;
  border-left: 3px solid $gray300;
  text-align: left;
  font-size: $fontSmall;
  width: 100%;

  :first-child {
    font-weight: $fontSemibold;
    color: $gray700;
  }

  p {
    color: $gray500;
  }

  div {
    margin-top: 2px;
  }

  &.info_grey {
    background-color: $gray50;
    border-color: $gray400;
    border-bottom: none;
  }

  &.error {
    background-color: $error25;
    border-color: $errorBorder;
    border-bottom: none;
  }

  &.success {
    background-color: $success25;
    border-color: $successBorder;
    border-bottom: none;
  }

  &.warning {
    background-color: $warning25;
    border-color: $warningBorder;
    border-bottom: none;
  }
}

.availability {
  @include flex;
  gap: $padding;
  justify-content: space-between;
  padding-left: $padding;
  cursor: pointer;
  user-select: none;

  .status {
    margin-top: 2px;
  }
}

.noAppointments {
  height: 100%;
  padding: $padding * 2 $padding;
}

.allDayWrapper {
  @include flex;
  gap: calc($padding/4);
  align-items: center;

  .allDayEvent {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    @include center;
    gap: calc($padding/4);

    &.withText {
      width: unset;
      height: unset;
      padding: calc($padding/4) calc($padding/2);
      border-radius: $borderRadius;
      text-wrap: nowrap;
      font-size: $fontSmall;
      color: $gray700;
    }
  }

  .allDayEventTail {
    width: 100%;
    height: 4px;
    margin-left: -1 * calc($padding/4);
  }
}

// ChatGPT generated styles
.calendarSched {
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  height: 100%;
  width: 100%;
  overflow: hidden;

  .calendarHeader {
    display: grid;
    grid-template-columns: auto repeat(2, 1fr);
    position: sticky;
    top: 0;
    background-color: #f0f0f0;
    z-index: 2;

    .calendarDay {
      border: 1px solid #ddd;
      padding: 10px;
      text-align: center;
    }

    .calendarCorner {
      border: 1px solid #ddd;
      background-color: #f0f0f0;
    }
  }

  .calendarBody {
    /* IE and Edge */
    -ms-overflow-style: none;
    /* Firefox */
    scrollbar-width: none;

    display: grid;
    grid-template-columns: auto 1fr;
    height: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .calendarSide {
    display: grid;
    grid-template-rows: repeat(6, auto);
    position: sticky;
    left: 0;
    background-color: #fff;
    z-index: 1;

    &.calendarTime {
      border: 1px solid #ddd;
      padding: 10px;
      text-align: right;
      background-color: #f9f9f9;
    }
  }

  .calendarGrid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(6, auto);
  }

  .calendarCell {
    border: 1px solid #ddd;
    padding: 10px;
    background-color: #fff;
  }
}
