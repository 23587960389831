@import "@/styles/variables.scss";

.Error {
  @include flexCol;
  gap: $padding;
  padding: 1.5 * $padding;

  &.isComponentScoped {
    .headerWrapper {
      display: none;
    }
    .message {
      box-shadow: unset;
      p {
        font-size: $fontBase;
        line-height: 20px;
      }
    }
  }

  h1 {
    margin-bottom: $padding / 2;
  }

  .message {
    @include card;
    border-color: $warningBorder;
    background-color: $warning25;
    color: $warningText;
    padding: $padding;
    display: flex;
    gap: $padding/2;
    p {
      margin-top: -2px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
