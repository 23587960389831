@import "./variables.scss";
@import "react-loading-skeleton/dist/skeleton.css";
@import "@syncfusion/ej2-base/styles/material.css";
@import "@syncfusion/ej2-buttons/styles/material.css";
@import "@syncfusion/ej2-dropdowns/styles/material.css";
@import "@syncfusion/ej2-inputs/styles/material.css";
@import "@syncfusion/ej2-navigations/styles/material.css";
@import "@syncfusion/ej2-popups/styles/material.css";
@import "@syncfusion/ej2-splitbuttons/styles/material.css";
@import "@syncfusion/ej2-pdfviewer/styles/material.css";

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, "Cascadia Mono", "Segoe UI Mono",
    "Roboto Mono", "Oxygen Mono", "Ubuntu Monospace", "Source Code Pro",
    "Fira Mono", "Droid Sans Mono", "Courier New", monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(
    from 180deg at 50% 50%,
    #16abff33 0deg,
    #0885ff33 55deg,
    #54d6ff33 120deg,
    #0071ff33 160deg,
    transparent 360deg
  );
  --secondary-glow: radial-gradient(
    rgba(255, 255, 255, 1),
    rgba(255, 255, 255, 0)
  );

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(
    #00000080,
    #00000040,
    #00000030,
    #00000020,
    #00000010,
    #00000010,
    #00000080
  );

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;

  /* Hide visual scrollbars */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  /* Force light-mode since we don't support dark-mode yet */
  color-scheme: only light;
}

/* Hide scrollbar for Chrome, Safari and Opera
This hides it for all child components but keeps the main scrollbar */
body *::-webkit-scrollbar {
  display: none;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
  max-height: 100vh;
  background-color: $white;
}

a {
  color: inherit;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }
}

button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  font-family: inherit;
  &:disabled {
    cursor: not-allowed;
  }
}

// TipTap Styles (at the top so they're easily overwritable

/* Basic editor styles
TODO(Landon): Customize these, I just copied them from TipTap's site */
.ProseMirror {
  height: 100%;
  width: 100%;
  color: $gray900;
  line-height: 24px;

  &[contenteditable="false"]:not(.rendered) {
    cursor: not-allowed;
  }

  > * {
    font-family: inherit;
    line-height: inherit;
  }

  &.ProseMirror-focused {
    outline: none;
  }

  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1.25rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: var(--font-mono);
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }

  // Placeholder
  p.is-editor-empty:first-child::before {
    color: $gray400;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  .padded {
    padding: $padding;
  }

  // Checkboxes (task list)
  ul[data-type="taskList"] {
    list-style: none;
    padding: 0 !important;

    p {
      margin: 0;
    }

    li {
      display: flex;
      align-items: center;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
        display: flex;

        input {
          height: 15px;
          width: 15px;
        }
      }

      > div {
        flex: 1 1 auto;
      }

      ul li,
      ol li {
        display: list-item;
      }

      ul[data-type="taskList"] > li {
        display: flex;
      }
    }
  }

  // temp: chatGPT converted the checkmark into a dataURI
  // need to debug why url to svg not working
  $dataURI: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10 3.63281L4.5 9.13281L2 6.63281' stroke='%23667085' stroke-width='1.6666' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");

  // checkbox styles
  input[type="checkbox"] {
    /* remove standard background appearance... */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    /* create custom radio button appearance */
    display: inline-block;
    width: 15px;
    height: 15px;
    padding: 3px;
    border-radius: 4px;
    border: 1px solid $gray400;
    cursor: pointer;
    position: relative;
    background-color: $white;

    &:checked {
      &:before {
        content: $dataURI;
        position: absolute;
        top: 0px;
        right: 1px;
      }
      border: 1px solid $gray400;
      background-color: $gray50;
    }
  }

  &.rendered {
    input[type="checkbox"] {
      cursor: default;
    }

    * {
      text-transform: initial !important;
    }

    ul {
      list-style: initial !important;

      li {
        padding: 0 !important;
      }
    }
  }
}

// Typography
h1 {
  font-weight: $fontSemibold;
  font-size: $fontXL;
}

p {
  font-size: 14px;
  line-height: 20px;
}

/* Text styles */
// Text xs in Figma
.t5 {
  font-size: 12px;
  line-height: 18px;
}

// Text sm in Figma
.t4 {
  font-size: 14px;
  line-height: 20px;
}

// Text md
.t3 {
  font-size: 16px;
  line-height: 24px;
}

// Text lg in Figma
.t2 {
  font-size: 18px;
  line-height: 28px;
}

// Text xl in Figma
.t1,
h3 {
  font-size: 20px;
  line-height: 30px;
}
// font weights

// Normal
p,
.t1,
.t2,
.t3,
.t4 {
  font-weight: 400;
}

// Medium
.tMd {
  font-weight: 500;
}

// Medium
.tBld {
  font-weight: 700;
}

// text colors
.gray400 {
  color: $gray400;
}

.gray700 {
  color: $gray700;
}

.gray900 {
  color: $gray900;
}

.blue700 {
  color: $parioBlue;
}

.xLight {
  color: $gray500;
}

.light {
  color: $gray600;
}

.white {
  color: $white;
}

.med {
  color: $gray700;
}

.dark {
  color: $gray900;
}

.flexCol {
  @include flexCol;
  gap: $padding;
}

.fixedAtBottom {
  position: fixed;
  bottom: 20px;
  left: 100px;
  width: 90%;
  max-width: 1200px;
}

.flex {
  display: flex;
  gap: $padding;
  align-items: center;
}

.apart {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.padded {
  padding: $padding;
}

.padded-x {
  padding: 0 $padding;
}

.wrapper {
  @include flexCol;
  gap: $padding;
}

.fullWidth {
  width: 100%;
}

.construction {
  @include card;
  @include flex;
  @include center;
  gap: $padding/2;
  padding: $padding;
  width: 100%;
  box-shadow: none;
  border: 1.5px dashed $warningBorder;
  background-color: $warning25;
}

.note {
  @include flex;
  color: $gray500;
  font-size: $fontSmall;
  background: $primary50;
  padding: $padding/2;
  border-radius: $borderRadius;
  align-items: center;
  gap: $padding/2;
  &.warning {
    background: $warning25;
    color: $warningText;
    &.bordered {
      border: 1px solid $warningBorder;
    }
  }
}

.grid2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: $padding/2;
}
.emptyState {
  @include card;
  @include flex;
  padding: 10px;
  width: 100%;
  box-shadow: none;
  border: none;
  background-color: $primary25;
  color: $gray400;
  font-size: 14px;
  line-height: 20px;
}

.capitalize {
  text-transform: capitalize;
}

.success {
  color: $successBorder;
}

.error {
  color: $errorBorder;
  background-color: $error50;
  border-radius: $borderRadius;
  border: 1px solid $errorBorder;
  font-size: $fontBase;
}

.errorText {
  color: $errorBorder;
}

.center {
  @include center;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}
.note {
  @include flex;
  color: $gray500;
  font-size: $fontSmall;
  background: $primary50;
  padding: $padding/2;
  border-radius: $borderRadius;
  align-items: center;
  gap: $padding/2;
}

.horizontal {
  @include flex;
  gap: $padding;
  height: 1px;
  width: 100%;
  background-color: $grey200;
}

/*
TODO: Better utitlity styles with new CSS framework
*/
label.standardLabel,
label.horizontalLabel {
  display: inline-block;
  color: rgb(52, 64, 84);
  margin: 4px 2px;
  font-size: 12px;

  // Occasionally, this isn't needed for alignment
  > span {
    margin-bottom: 4px;
    display: inline-block;
  }
}

.standardInput {
  max-width: 320px;
  position: relative;
  font-size: $fontBase;
  font-weight: $fontReg;
  line-height: 24px;
  font-family: inherit;
  padding: 5px 12px;
  background-color: $white;
  border: 1px solid $gray300;
  border-radius: $borderRadius;
  color: $gray500;
  width: 100%;
}

label.horizontalLabel {
  max-width: unset;
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 2fr;
}

/* Display Styles */

.d1,
.d2,
.d3,
.d4 {
  font-weight: 500;
}

.d3,
h1,
h2 {
  font-size: 30px;
  line-height: 38px;
}

h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
}

.d4 {
  font-size: 24px;
  line-height: 32px;
}

.smBld {
  font-weight: 500;
}

.RecordTransferPdf {
  & > div {
    max-width: 595pt;
    overflow-x: hidden;
    min-height: 841pt;
    padding: 40px 40px;
    page-break-inside: avoid;
  }

  .coverSheet {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  p,
  th,
  td {
    color: black !important;
    vertical-align: top;
  }
  h1,
  h2,
  h3,
  h4 {
    color: black !important;
  }
}
